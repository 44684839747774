const HOST = location.host;

let BASE_URL;
let ENV;
if(HOST==='localhost:3000'){
	ENV = 'DEV';
	BASE_URL='https://mp.quick58.com'
}else{
	ENV='PROD';
	BASE_URL='https://mp.quick58.com'
}
let SHAREOPT = {
	title: '分享标题', // 分享标题
    desc: '分享描述', // 分享描述
    link: 'https://mp.quick58.com/index.html', // 分享链接
    imgUrl: '', // 分享图标
}
export default {BASE_URL,ENV,SHAREOPT};