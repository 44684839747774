import asyncComponent from '../asyncComponent';

const Home = asyncComponent(()=>import('../containers/Home/index'));
const Plan = asyncComponent(()=>import('../containers/Plan/index'));
const User = asyncComponent(()=>import('../containers/User/index'));
const Message = asyncComponent(()=>import('../containers/Message/index'));
const Orders = asyncComponent(()=>import('../containers/Orders/index'));
const Recharge = asyncComponent(()=>import('../containers/Recharge/index'));
const Account = asyncComponent(()=>import('../containers/Account/index'));
const MatchDetail = asyncComponent(()=>import('../containers/MatchDetail/index'));
const PlanDetail = asyncComponent(()=>import('../containers/PlanDetail/index'));
const Help = asyncComponent(()=>import('../containers/Help/index'));
const Law = asyncComponent(()=>import('../containers/Law/index'));

const routes = [
  { path: '/', 
  	name: 'Home', 
  	component: Home 
  },
  { path: '/plan', 
  	name: 'Plan', 
  	component: Plan 
  },
  { path: '/user', 
  	name: 'User', 
  	component: User 
  },
  { path: '/message', 
  	name: 'Message', 
  	component: Message 
  },
  { path: '/orders', 
  	name: 'Orders', 
  	component: Orders 
  },
  { path: '/recharge', 
  	name: 'Recharge', 
  	component: Recharge 
  },
  { path: '/account', 
  	name: 'Account', 
  	component: Account 
  },
  { path: '/matchdetail/:id', 
  	name: 'MatchDetail', 
  	component: MatchDetail 
  },
  { path: '/plandetail/:id', 
  	name: 'PlanDetail', 
  	component: PlanDetail 
  },
  { path: '/help', 
    name: 'Help', 
    component: Help 
  },
  { path: '/law', 
    name: 'Law', 
    component: Law 
  }
];

export default routes;
export { default as App } from '../containers/App/index';
