import React from 'react';
import {withRouter,NavLink} from 'react-router-dom';
import LineItem from '../LineItem/index';
import {dateFormat,timeFormat} from '../../utils/filter';
import './style.less';

const MainItem = ({item})=>{
	return (
	<NavLink className="main-item-wrap" to={`/plandetail/${item.FinancialId}`}>
      <div className="itemTop">
        <div className="itl">{item.LeftGroup}</div>
        <div className="itm">{item.EventName}</div>
        <div className="itr">{item.RightGroup}</div>
      </div>
      <div className="matchInfo">
        <div className="mil">
          <div className="matchThumb">
            <img src={item.LeftIcon} />
          </div>
          <div className="teamName1"><div className="nameInner">{item.LeftTeamName}</div></div>
        </div>
        <div className="mim">
          <div className="matchDate">{dateFormat(item.EventTime)}</div>
          <div className="matchTime">{timeFormat(item.EventTime)}</div>
        </div>
        <div className="mir">
          <div className="teamName2"><div className="nameInner">{item.RightTeamName}</div></div>
          <div className="matchThumb">
          	<img src={item.RightIcon} />
          </div>
        </div>
      </div>
    </NavLink>
	)
}

export default MainItem;