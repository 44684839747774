import http from './http';
import consts from './consts';
import wx from 'weixin-js-sdk';

let {SHAREOPT} = consts;
export const dateFormat = (timestamp)=> {
    let date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    let D = date.getDate()<10?'0'+date.getDate():date.getDate();
    return Y+M+D;
}

export const timeFormat = (timestamp)=> {
    let date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes());
    return h+m;
}
export const dayFormat = (timestamp)=>{
	let date = new Date(timestamp * 1000);
	let day = date.getDay();
	let week = '';
	switch (date.getDay()) {
	  case 0:week="星期天";break
	  case 1:week="星期一";break
	  case 2:week="星期二";break
	  case 3:week="星期三";break
	  case 4:week="星期四";break
	  case 5:week="星期五";break
	  case 6:week="星期六";break
	}
	return week;
}

export const getQueryString = (name) => {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.href.split('?')[1].match(reg);
    if (r != null) {
    	if(unescape(r[2]).indexOf('#')>-1){
    		var s = unescape(r[2]).split('#')[0];
        	return s;
    	}    
    }
    return null;
}

export const wxConfig = ()=>{
	let url = location.href.indexOf('#')>-1?location.href.split('#')[0]:location.href;
	http.request('GET','/api/jssdk',{url}).then(res=>{
      console.log(res);
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.appId, // 必填，公众号的唯一标识
        timestamp: res.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.nonceStr, // 必填，生成签名的随机串
        signature: res.signature  ,// 必填，签名，见附录1
        jsApiList: [
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'chooseWXPay'
        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
    })
}
export const wxShareTimeline = ()=>{
	let {title,imgUrl,link,desc} = SHAREOPT;
	wx.onMenuShareTimeline({
    	title, // 分享标题
    	link, // 分享链接
    	imgUrl, // 分享图标
    	success: function () { 
        // 用户确认分享后执行的回调函数
    	},
   		cancel: function () { 
        // 用户取消分享后执行的回调函数
    	}
	});
}
export const wxShareAppMessage = ()=>{
	let {title,imgUrl,link,desc} = SHAREOPT;
	wx.onMenuShareAppMessage({
    	title, // 分享标题
    	link, // 分享链接
    	imgUrl, // 分享图标
    	desc,
    	success: function () { 
        // 用户确认分享后执行的回调函数
    	},
   		cancel: function () { 
        // 用户取消分享后执行的回调函数
    	}
	});
}