import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {withRouter,NavLink} from 'react-router-dom';
import {TabBar} from 'antd-mobile';
import http from '../../utils/http';
import alert from '../../utils/alert';
import UserItem from '../../components/UserItem/index';
import Footer from '../../components/Footer/index';
import './style.less';

class FinancialItem extends Component {

  constructor(props, context) {
    super(props, context);
    this.state={
	    joinStatus:this.props.plan.Subscribe?1:0,//未加入
    	joinText:this.props.plan.Subscribe?'取消关注':'关注'
    }
  }
  componentWillMount(){
  	console.log(this.props)
  }
  componentDidMount(){

  }
  componentWillUnmount(){
    this.setState = (state,callback)=>{
      return;
    };
  }
  linkto=()=>{
    this.props.history.push(`/plandetail/${this.props.plan.Id}`)
  }
  join = ()=>{
      if (this.state.joinStatus==0){
        http.request('POST','/api/subscribe/join', { id: this.props.plan.Id }).then((res) => {
          this.setState({
            joinStatus: 1,//加入
          })
        })
      }else{
        http.request('POST','/api/subscribe/quit', { id: this.props.plan.Id }).then((res) => {
          this.setState({
            joinStatus: 0,//未加入
          })
        })
      }
    }
  render() {
  	const plan = this.props.plan;
    return (
		<div className="planCard">
	      <div className="pcTop">
	        <div className="pcTitle">
	          {plan.Name}
	          <p>{plan.Status}</p>
	        </div>
	        <div className="pcnvg">
	          <NavLink to={`/plandetail/${plan.Id}`}>查看详情 ></NavLink>
	        </div>
	      </div>
	      <div className="pcCon">
	        <div className="pcConTop" onClick={this.linkto}>
	          <div className="pcThumb">
	            <img src={plan.Icon}></img>
	          </div>
	          <div className="pcInfo">
	          	{plan.LineInfo.map((item,index)=>{
	          		return (<div key={index}>{item[0]} <span>{item[1]}</span></div>)
	          	})}
	          </div>
	          <div className="pcResult">
	            <div className="result1">{(plan.WinningRate)/100}%</div>
	            <div className="result2">{plan.Status=="进行中"?'胜率':'收益率'}</div>
	          </div>
	        </div>
	        <div className="pcConBottom">
	          <div className="pcAdvert">{plan.Advert}</div>
	          <div className={`pcJoin ${this.state.joinStatus==0?'':'disable'}`} onClick={this.join}>
	            {this.state.joinStatus==0?'关注':'取消关注'}
	          </div>
	        </div>
	      </div>
	    </div>
	)
  }
}

export default FinancialItem;
