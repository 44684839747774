import React from 'react';
import {withRouter,NavLink} from 'react-router-dom';
import icon from './images/icon_more.png';
import './style.less';

const PlanItem = ({plan,buy,goMatch})=>{
	return (
		<div className={`pdItem ${plan.styles}`}>
		  <div onClick={goMatch} className="itemLeft">
		    <div className="itemLeague">
		      <span>{plan.EventName} </span><span> {plan.date} {plan.time}</span>
		    </div>
		    <div className="itemDetail">
		      <div className="idTeams">
		        <div className="idTeam">
		          <div className="idThumb">
		            <img src={plan.LeftIcon}></img>
		          </div>
		          <div className="idName">
		            <div className="nameInner">{plan.LeftTeamName}</div>
		          </div>
		        </div>
		        <div className="idMid">{plan.Score}</div>
		        <div className="idTeam flexRight"> 
		          <div className="idThumb">
		            <img src={plan.RightIcon}></img>
		          </div>
		          <div className="idName">
		            <div className="nameInner">{plan.RightTeamName}</div>
		          </div>
		        </div>
		      </div>
		    </div>
		    <div className="itemRemark">
		      {plan.showBtn?<span className="hlprice">价格：{plan.Price.toFixed(2)}金币</span>:null}
		    </div>
		  </div>
		  <form className='itemRight'>
		  	{!plan.showBtn?<div className="result1">{plan.Status}</div>:<button className="result2" onClick={buy}>购买</button>}
		  </form>
		</div>
	)
}

export default PlanItem;