import {Toast,Modal} from 'antd-mobile';
const alert = Modal.alert;

export default{
	info:(text,duration=5)=>{
		Toast.info(text,duration)
	},
	success:text=>{
		Toast.success(text,2)
	},
	fail:text=>{
		Toast.fail(text,2)
	},
	loading:text=>{
		Toast.loading(text,0);
	},
	hideToast:()=>{
		Toast.hide();
	},
	confirm:(title,text,onCancel,onOk)=>{
		alert(title,text, [
          { text: '取消', onPress: () => onCancel() },
          { text: '确认', onPress: () => onOk() },
        ])
	}
}