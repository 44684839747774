import React from 'react';
import icon from './images/icon_more.png';
import './style.less'

const MsgItem = ({date,time,content,isFold,openMsg})=>{
	return (
		<div className="msgItem extend" onClick={openMsg}>
		  <div className="msgDate">{date}\t{time}</div>
		  <div className="msgCard">
		    <div className={`msgArrow ${isFold?'rightArrow':'downArrow'}`}>
		      <img src={icon}></img>
		    </div>
		    <div className="msgTxt">{content}</div>
		  </div>
		</div>
	)
}

export default MsgItem;