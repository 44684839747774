import React from 'react';
import {NavLink} from 'react-router-dom'
import icon from './images/icon_more.png';

const UserItem = ({coin,label,link})=>{
	return (
		<NavLink className={`userItem flexRight ${label=='法律声明'?'nobd':''}`} to={link}>
		    {label=='我的金币'?<div className="userMoney"><p>{coin.toFixed(2)}</p></div>:<div className="userArrow">
		      <img src={icon}></img>
		    </div>}
		    <div className="userLabel">{label}</div>
		</NavLink>
	)
}

export default UserItem;