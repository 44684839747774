import React from 'react';
import './style.less';
import tab1 from './images/tab1.png';
import tab1_hl from './images/tab1_selected.png';
import tab2 from './images/tab2.png';
import tab2_hl from './images/tab2_selected.png';
import tab3 from './images/tab3.png';
import tab3_hl from './images/tab3_selected.png';

const Footer = ({index,goIndex,goPlan,goUser})=>{
	return (
		<div className="footer-wrap">
			<div className="footer-item" onClick={goIndex}>
				<div className="footer-thumb">
					<img src={index==1?tab1_hl:tab1} />
				</div>
				<p className="footer-text">
					首页
				</p>
			</div>
			<div className="footer-item" onClick={goPlan}>
				<div className="footer-thumb">
					<img src={index==2?tab2_hl:tab2} />
				</div>
				<p className="footer-text">
					最新计划
				</p>
			</div>
			<div className="footer-item" onClick={goUser}>
				<div className="footer-thumb">
					<img src={index==3?tab3_hl:tab3} />
				</div>
				<p className="footer-text">
					我的
				</p>
			</div>
		</div>
	)
}

export default Footer;